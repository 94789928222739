import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseModule from "@web3-onboard/coinbase";
import walletConnectModule from "@web3-onboard/walletconnect";
import trackWallet from "./TrackWallet/index.js";
import ensuroLogo from "../../assets/images/logo-dark.png";
import icon from "../../assets/images/ensuro-icon.png";
import { chains } from "../../availableChains.js";
import { walletConnect as wc } from "../../config";
import { init } from "@web3-onboard/react";

const injected = injectedModule();
const coinbase = coinbaseModule();
const myPlugin = trackWallet();

const walletConnect = walletConnectModule({
  version: 2,
  handleUri: (uri) => console.log(uri),
  requiredChains: chains.map((c) => c.id),
  projectId: wc.projectId,
});

export const initWeb3Onboard = init({
  wallets: [injected, coinbase, walletConnect, myPlugin],
  chains: chains.map((c) => ({
    id: "0x" + parseInt(c.id).toString(16),
    label: c.name,
    rpcUrl: c.rpc,
  })),
  appMetadata: {
    name: "NoLiq - Ensuro",
    icon: icon,
    logo: ensuroLogo,
    description: "NoLiq - Ensuro",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" },
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
    ],
  },
  connect: {
    autoConnectLastWallet: true,
  },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
});
