const { ethers } = require("ethers");

const availableChains = process.env.REACT_APP_CHAINS?.split(",");

export const chains = availableChains?.map((chain) => ({
  id: parseInt(process.env[`REACT_APP_CHAIN_ID_${chain}`]),
  name: process.env[`REACT_APP_CHAIN_NAME_${chain}`],
  network: process.env[`REACT_APP_CHAIN_NETWORK_${chain}`],
  logo: require(`./assets/images/${process.env[`REACT_APP_ENV_NAME_${chain}`]}-logo.png`),
  rpc: process.env[`REACT_APP_CHAIN_RPC_${chain}`],
  scan: process.env[`REACT_APP_CHAIN_SCAN_${chain}`],
  provider: new ethers.JsonRpcProvider(process.env[`REACT_APP_CHAIN_RPC_${chain}`]),
  currency: {
    name: process.env[`REACT_APP_CURRENCY_NAME_${chain}`],
    address: process.env[`REACT_APP_CURRENCY_ADDRESS_${chain}`],
    version: process.env[`REACT_APP_CURRENCY_VERSION_${chain}`],
    decimals: parseInt(
      process.env[`REACT_APP_CURRENCY_DECIMALS_${chain}`] || process.env.REACT_APP_CURRENCY_DECIMALS_DEFAULT
    ),
  },
  environment: {
    name: process.env[`REACT_APP_ENV_NAME_${chain}`],
    testnet: (process.env[`REACT_APP_IS_TESTNET_${chain}`] || "false") === "true",
    network: process.env[`REACT_APP_ASSETS_NETWORK_${chain}`],
    version: process.env[`REACT_APP_ENV_VERSION_${chain}`] || "development",
  },
  aave: {
    pool: process.env[`REACT_APP_AAVE_POOL_${chain}`],
  },
  policy: {
    minimumPrice: 1,
  },
}));
