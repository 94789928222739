import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import ActivePoliciesList from "./ActivePoliciesList";
import { Row } from "reactstrap";
/* Helpers and Selectors */
import { selectCurrentChain, selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
import { getChainDataById } from "../../helpers/eth_store_helper";
import { selectUserAddress } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { automations } from "../../chainConfigs.js";
import "./style.scss";

const componentEthCalls = (userAddress, chain) => {
  return automations[chain.environment.network].map((aut) => {
    return { address: aut.address, abi: "PayoutAutomation", method: "balanceOf", args: [userAddress] };
  });
};

const ActivePolicies = ({ t, userAddress, automationBalances, currentChain }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "activePolicies",
      componentEthCalls: componentEthCalls(userAddress, currentChain),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "activePolicies" });
      mounted.current = false;
    };
  }, [dispatch, userAddress, currentChain]);

  return (
    <>
      {automationBalances.some((ab) => ab?.value > 0) && (
        <>
          <h3>{t("Active policies")}</h3>
          <br />
          <Row>
            {automations[currentChain.environment.network].map((aut, autIdx) => {
              return (
                automationBalances[autIdx]?.value > 0 && (
                  <ActivePoliciesList
                    userAddress={userAddress}
                    balance={automationBalances[autIdx].value}
                    contract={aut.address}
                    automation={t(aut.name)}
                    key={autIdx}
                  />
                )
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

ActivePolicies.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userAddress = selectUserAddress(state.UserReducer);
  const c = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(c.id);
  const automationBalances = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(userAddress, currentChain));
  return { userAddress, automationBalances, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(ActivePolicies));
