import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../Common/Blurred/BlurredNumber";
/* Helpers and Selectors */
import { selectEthCallMultiple, selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./style.scss";

const componentEthCalls = (assetPriceOracle) => {
  return [{ address: assetPriceOracle, abi: "PriceOracle", method: "getCurrentPrice", args: [] }];
};

const LiquidationUnderText = ({ t, triggerPrice, asset, price, lower, currentChain }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);
  const triggerPriceValue =
    price?.value && triggerPrice?.value
      ? { value: (triggerPrice?.value - price?.value) / price?.value, state: "LOADED" }
      : {};

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "liqUnderText_" + asset.priceOracle,
      componentEthCalls: componentEthCalls(asset.priceOracle),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "liqUnderText_" + asset.priceOracle });
      mounted.current = false;
    };
  }, [dispatch, asset.priceOracle, currentChain]);

  return (
    <div id="liq-under-text">
      {lower ? t("Trigger if under") : t("Trigger if over")}:{" "}
      <BlurredNumber number={triggerPrice} options={{ notation: "standard" }} /> {"("}
      <span style={{ color: lower ? "red" : "green" }}>
        {lower ? "" : "+"}
        <BlurredNumber
          number={triggerPriceValue}
          options={{ maximumFractionDigits: 2 }}
          suffix={"%"}
          percentage={true}
        />
      </span>
      {")"}
    </div>
  );
};

LiquidationUnderText.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const { asset } = ownProps;
  const assetPriceOracle = asset.priceOracle;
  const [price] = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(assetPriceOracle));
  const currentChain = selectCurrentChain(state.EthereumReducer);
  return { price, asset, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(LiquidationUnderText));
