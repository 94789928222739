import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Alert } from "react-bootstrap";
/* Helpers and Selectors */
import { selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { getChainDataById } from "../../helpers/eth_store_helper";
import { selectProvider } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./style.scss";

const WrongNetworkAlert = ({ t, userState, currentChain }) => {
  let dispatch = useDispatch();

  const switchNetwork = async (chain) => {
    let provider = selectProvider(userState);
    let hexId = "0x" + chain.id.toString(16);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexId }],
      });
      dispatch({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hexId,
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                blockExplorerUrls: [chain.scan],
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <Alert variant="danger">
      {t("Please switch to ")} {currentChain.name}.
      <strong id="btn-text" onClick={() => switchNetwork(currentChain)}>
        {t("[Switch network]")}
      </strong>
    </Alert>
  );
};

WrongNetworkAlert.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userState = state.UserReducer;
  const c = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(c.id);
  return { userState, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(WrongNetworkAlert));
