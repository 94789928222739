import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Big from "big.js";
/* Components */
import { Form, Modal } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import AutomationDescription from "./AutomationDescription";
import WrongNetworkAlert from "./../WrongNetworkAlert";
import HealthFactorPoints from "./HealthFactorPoints";
import DurationButtons from "./DurationButtons";
import SupportedAssets from "./SupportedAssets";
import TriggerPriceForm from "./TriggerPriceForm";
import TxButton from "../../Common/TxButton";
import PolicyPrice from "./PolicyPrice";
import PayoutForm from "./PayoutForm";
import Loader from "react-js-loader";
import Automation from "./Automation";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
/* Helpers and Selectors */
import {
  selectAaveUserData,
  selectAsset,
  selectAssetPrice,
  selectAssetPrices,
  selectDuration,
  selectPayout,
  selectAutomation,
  selectTriggerPrice,
} from "../../../store/securePositionModal/selectors";
import {
  dispatchEIPSign,
  dispatchSecurePositionByAutomation,
  getAutomationAddress,
  priceCall,
} from "../../../helpers/secure_position_helper";
import { BNToDecimal, decimalToBN, strToBlockchainData, strToDec } from "../../../helpers/number_format_helper";
import {
  selectBiggerSign,
  selectEthCallMultiple,
  selectLastTransact,
  selectCurrentChain,
} from "ethereum-store/src/store/ethereum/selectors";
import { getChainDataById, validTransaction } from "../../../helpers/eth_store_helper";
import { selectChainId } from "../../../store/user/selectors";
import { assets } from "../../../chainConfigs";
import "./../style.scss";
import _ from "lodash";

const componentEthCalls = (userAddress, chain) => {
  let calls = [];
  calls.push({ address: chain.currency.address, abi: "ERC20Permit", method: "nonces", args: [userAddress] });
  assets[chain.environment.network].forEach((a) => {
    calls.push({ address: a.priceOracle, abi: "PriceOracle", method: "getCurrentPrice", args: [] });
  });
  return calls;
};

const SecurePositionModal = ({
  t,
  user,
  show,
  onHide,
  lastTransaction,
  collateral,
  debt,
  healthFactor,
  chainId,
  prices,
  asset,
  automation,
  aaveUserData,
  storedAssetPrices,
  assetPrice,
  triggerPrice,
  payoutAmount,
  duration,
  premium,
  permit,
  currentChain,
}) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    if ((!aaveUserData || _.isEmpty(storedAssetPrices)) && !_.isEmpty(prices)) {
      dispatch({
        type: "SET_BLOCKCHAIN_STATE",
        prices: prices,
        aaveUserData: {
          totalCollateralBase: collateral,
          totalDebtBase: debt,
          healthFactor: healthFactor,
        },
        chainId: chainId,
      });
    }
  }, [aaveUserData, chainId, collateral, debt, dispatch, healthFactor, prices, storedAssetPrices]);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "priceOracle",
      componentEthCalls: componentEthCalls(user.address, currentChain),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "priceOracle" });
      mounted.current = false;
    };
  }, [dispatch, user.address, currentChain]);

  const requiresApprove = () => {
    const permitAmount = permit?.value?.value ? BNToDecimal(permit.value.value, 6) : 0;
    return premium?.value && (!permitAmount || permitAmount.lt(premium.value));
  };

  const validForm = () => {
    return (
      chainId === currentChain.id &&
      strToDec(triggerPrice) &&
      strToDec(payoutAmount) &&
      premium?.value >= currentChain.policy.minimumPrice &&
      debt?.value?.gt(strToDec(payoutAmount))
    );
  };

  const dispatchApprove = async () => {
    if (!validTransaction(lastTransaction)) return;
    if (requiresApprove() && premium?.value) {
      dispatchEIPSign(dispatch, automation, user.address, decimalToBN(premium.value), currentChain.id);
    }
  };

  const dispatchTransfer = () => {
    if (!validTransaction(lastTransaction)) return;
    if (validForm() && permit?.value?.value && premium?.value) {
      dispatchSecurePositionByAutomation(
        dispatch,
        automation,
        asset,
        strToBlockchainData(triggerPrice, 18),
        assetPrice?.value.gt(Big(triggerPrice)),
        strToBlockchainData(payoutAmount, 6),
        duration.timestamp,
        user.address,
        permit,
        currentChain.id
      );
    }
  };

  return (
    <Modal show={show} centered onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Secure your position")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!aaveUserData || _.isEmpty(storedAssetPrices) ? (
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        ) : (
          <Form>
            {chainId !== currentChain.id && <WrongNetworkAlert />}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Asset")}</Form.Label>
                  <SupportedAssets />
                </Form.Group>
                <TriggerPriceForm />
              </Col>
              <Col className="only-desktop" md={6} style={{ alignContent: "center" }}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Coverage Price")}</Form.Label>
                  <PolicyPrice />
                </Form.Group>
              </Col>
              <Col md={6}>
                <PayoutForm />
                {!_.isEmpty(asset) && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Duration")}</Form.Label>
                    <DurationButtons />
                  </Form.Group>
                )}
              </Col>
              <Col md={6} className="only-desktop">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Health Factor")}</Form.Label>
                  <HealthFactorPoints hf={healthFactor} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Automation")}</Form.Label>
                  <Automation />
                </Form.Group>
              </Col>
              <Col className="only-mobile" md={6} style={{ alignContent: "center" }}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Coverage Price")}</Form.Label>
                  <PolicyPrice />
                </Form.Group>
              </Col>
              <Col md={6} className="only-mobile">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Health Factor")}</Form.Label>
                  <HealthFactorPoints hf={healthFactor} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <AutomationDescription />
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <em style={{ color: "red" }}>{lastTransaction ? lastTransaction.error : ""}</em>
        <TxButton
          chainId={chainId}
          currentChain={currentChain}
          disabled={!validForm() || (user && !user.canTransact)}
          onClick={requiresApprove() ? dispatchApprove : dispatchTransfer}
          text={requiresApprove() ? t("Approve to continue") : t("Secure Position")}
        />
        {user && !user.canTransact && (
          <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
        )}
      </Modal.Footer>
    </Modal>
  );
};

SecurePositionModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const chainId = selectChainId(state.UserReducer);
  const lastTransaction = selectLastTransact(state.EthereumReducer);
  const c = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(c.id);
  const [nonces, ...oraclePrices] = selectEthCallMultiple(
    state.EthereumReducer,
    componentEthCalls(user.address, currentChain)
  );

  // Prices is required to be empty if not all prices are available because SET_BLOCKCHAIN_DATA will only be dispatched once
  let prices = {};
  if (oraclePrices.length === assets[currentChain.environment.network].length && oraclePrices.every((p) => p?.value)) {
    assets[currentChain.environment.network].forEach((asset, idx) => {
      prices[asset.name] = oraclePrices[idx];
    });
  }

  const asset = selectAsset(state.SecurePositionModalReducer);
  const automation = selectAutomation(state.SecurePositionModalReducer);
  const automationAddress = getAutomationAddress(automation, c.id);
  const permit = selectBiggerSign(state.EthereumReducer, user.address, nonces?.value, automationAddress);

  /* To check if store is empty -> if is empty, we have to store the data */
  const aaveUserData = selectAaveUserData(state.SecurePositionModalReducer);
  const storedAssetPrices = selectAssetPrices(state.SecurePositionModalReducer);

  /* Values to send transaction */
  const assetPrice = selectAssetPrice(state.SecurePositionModalReducer);
  const triggerPrice = selectTriggerPrice(state.SecurePositionModalReducer);
  const payoutAmount = selectPayout(state.SecurePositionModalReducer);
  const duration = selectDuration(state.SecurePositionModalReducer);
  const [policyPrice] = selectEthCallMultiple(
    state.EthereumReducer,
    priceCall(asset, assetPrice, triggerPrice, payoutAmount, duration)
  ) || [{}];

  const premium = policyPrice?.value ? { value: BNToDecimal(policyPrice.value?.premium, 6), state: "LOADED" } : {};
  return {
    state,
    user,
    lastTransaction,
    chainId,
    prices,
    asset,
    automation,
    aaveUserData,
    storedAssetPrices,
    assetPrice,
    triggerPrice,
    payoutAmount,
    duration,
    premium,
    permit,
    currentChain,
  };
};

export default connect(mapStateToProps)(withTranslation()(SecurePositionModal));
