import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../Common/Blurred/BlurredNumber";
import LiquidationUnderText from "./LiquidationUnderText";
import { Card, Col, Row } from "reactstrap";
import Loader from "react-js-loader";
import Asset from "../Common/Asset";
/* Helpers and Selectors */
import { BNToDecimal, getDateFromTimestamp } from "../../helpers/number_format_helper";
import { getAssetByPriceRM } from "../../helpers/secure_position_helper";
import { selectEthCallMultiple, selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./style.scss";

const { ethers } = require("ethers");

const componentEthCalls = (policyId, priceRm) => {
  if (policyId && priceRm)
    return [{ address: priceRm, abi: "PriceRiskModule", method: "getPolicyData", args: [policyId] }];
  return [];
};

const PolicyCard = ({ t, policyId, policyData, priceRm, asset, automation, currentChain }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  const payout =
    policyData?.value && policyData?.value[0]?.payout
      ? { value: BNToDecimal(policyData.value[0].payout, 6), state: "LOADED" }
      : undefined;
  const triggerPrice =
    policyData?.value && policyData?.value?.triggerPrice
      ? { value: BNToDecimal(policyData.value.triggerPrice, 18), state: "LOADED" }
      : undefined;

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "policyData_" + policyId,
      componentEthCalls: componentEthCalls(policyId, priceRm),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "policyData_" + policyId });
      mounted.current = false;
    };
  }, [dispatch, policyId, priceRm, asset, currentChain]);

  return (
    <>
      {!(policyData && policyData.value) ? (
        <Col md="4" xs="12">
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        </Col>
      ) : (
        <Col md="4" xs="12">
          <Card id="active-policies-card">
            <LiquidationUnderText triggerPrice={triggerPrice} asset={asset} lower={policyData.value.lower} />
            <Row>
              <Col md={6} xs={6}>
                <Asset font={18} name={asset.rmName} />
              </Col>
              <Col md={6} xs={6}>
                <div id="automation-txt">{automation}</div>
              </Col>
              <Col md={6} xs={6}>
                <div id="expiration-text">
                  {t("Expiration")}: {getDateFromTimestamp(policyData.value[0]?.expiration)}
                </div>
              </Col>
              <Col md={6} xs={6} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                <div>
                  <div id="max-payout-number">
                    $<BlurredNumber number={payout} options={{ maximumFractionDigits: 2, notation: "compact" }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </>
  );
};

PolicyCard.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const { policyId } = ownProps;
  const priceRm = policyId && ethers.toBeHex(policyId).slice(0, 42);
  const [policyData] = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(policyId, priceRm));
  const currentChain = selectCurrentChain(state.EthereumReducer);
  const asset = policyData?.value ? getAssetByPriceRM(policyData.value[0].riskModule, currentChain.id) : undefined;
  return { policyData, priceRm, asset, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(PolicyCard));
