import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectAsset, selectDuration } from "../../../store/securePositionModal/selectors";
import { getTimestampFromDays } from "../../../helpers/secure_position_helper";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "../style.scss";

const DurationButtons = ({ t, duration, asset }) => {
  let dispatch = useDispatch();
  const handleSetDuration = (d) => {
    dispatch({ type: "SET_DURATION", duration: { days: d, timestamp: getTimestampFromDays(d) } });
  };

  return (
    <Row>
      {asset?.duration &&
        asset?.duration.map((d, i) => (
          <Col md={4} xs={4} key={i}>
            <Button
              id="duration-btn"
              variant={duration.days === d ? "secondary" : "sky-blue"}
              onClick={() => handleSetDuration(d)}
            >
              {d} {t("days")}
            </Button>
          </Col>
        ))}
    </Row>
  );
};

DurationButtons.propTypes = {
  t: PropTypes.any,
};
const mapStateToProps = (state) => {
  const duration = selectDuration(state.SecurePositionModalReducer);
  const asset = selectAsset(state.SecurePositionModalReducer);
  return { duration, asset };
};

export default connect(mapStateToProps)(withTranslation()(DurationButtons));
