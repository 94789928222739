import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { useDispatch } from "react-redux";

import { withTranslation } from "react-i18next";
import { initWeb3Onboard } from "./initOnboard";
import { selectUserAddress, selectUserAddressShort } from "../../store/user/selectors";
import useGeoLocation from "react-ipgeolocation";
import * as actionTypes from "../../store/user/actionTypes";
import colors from "../../colors";
import { refreshWallet } from "../../helpers/wallet_store_helper";
import { blockedCountries, track } from "../../config";

const ethers = require("ethers");

const ConnectWallet = ({ t, user }) => {
  let dispatch = useDispatch();
  const [{ wallet }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain }] = useSetChain();
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };

  const [, setWeb3Onboard] = useState(null);
  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, []);

  useEffect(() => {
    if (connectedChain) {
      dispatch({
        type: actionTypes.SET_CHAIN_ID,
        payload: parseInt(connectedChain.id, 16),
      });
    }
  }, [dispatch, connectedChain]);

  const connectWallet = useCallback(async () => {
    try {
      const wallets = await connect();
      const provider = wallets[0].provider;
      const library = new ethers.BrowserProvider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      const label = wallets[0].label;
      refreshWallet(dispatch, provider, accounts[0]?.address, network.chainId, label !== track.name);
    } catch (error) {}
  }, [connect, dispatch]);

  const disconnectWallet = async () => {
    await disconnect(wallet);
    window.localStorage.removeItem("onboard.js:last_connected_wallet");
    window.localStorage.removeItem("trackWalletAddress");
    dispatch({
      type: actionTypes.SET_PROVIDER,
      payload: null,
    });
    dispatch({
      type: actionTypes.SET_USER,
      payload: null,
    });
    dispatch({
      type: actionTypes.SET_CHAIN_ID,
      payload: null,
    });
  };

  useEffect(() => {
    if (wallet && window.localStorage.getItem("onboard.js:last_connected_wallet")) {
      const provider = wallet.provider;
      const label = wallet.label;
      refreshWallet(
        dispatch,
        provider,
        wallet.accounts[0].address,
        parseInt(wallet.chains[0].id, 16),
        label !== track.name
      );
    }
  }, [dispatch, wallet]);

  const location = useGeoLocation();

  return (
    <div className="d-lg-inline-block header-buttons">
      {!selectUserAddress(user) ? (
        <Button
          className="btn-outline-primary font-size-18 waves-effect"
          onClick={connectWallet}
          disabled={blockedCountries.includes(location.country)}
          size="lg"
          style={{ color: "white" }}
        >
          <i className="uil uil-wallet " /> {t("Connect")}
        </Button>
      ) : (
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
          <DropdownToggle
            className="font-size-20 btn header-item waves-effect"
            tag="button"
            style={{ color: colors.primary }}
          >
            <i className="uil uil-wallet " /> {selectUserAddressShort(user)}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end dropdown-border">
            <DropdownItem
              className="font-size-20"
              onClick={() => {
                navigator.clipboard.writeText(selectUserAddress(user));
              }}
            >
              <i className="uil uil-copy" /> {t("Copy Address")}
            </DropdownItem>
            <DropdownItem className="font-size-20" onClick={disconnectWallet}>
              <i className="uil uil-sign-out-alt" /> {t("Disconnect")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

ConnectWallet.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ConnectWallet);
