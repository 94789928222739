import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import PolicyCard from "./PolicyCard";
/* Helpers and Selectors */
import { selectEthCallMultiple, selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { selectUserAddress } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./style.scss";

const componentEthCalls = (userAddress, balance, contract) => {
  // one call for each policy
  const calls = Array.from({ length: Number(balance) }).map((_, i) => ({
    address: contract,
    abi: "PayoutAutomation",
    method: "tokenOfOwnerByIndex",
    args: [userAddress, i],
  }));
  return calls;
};

const ActivePoliciesList = ({ t, userAddress, balance, contract, policyIds, automation, currentChain }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "policyTokens_" + contract,
      componentEthCalls: componentEthCalls(userAddress, balance, contract),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "policyTokens_" + contract });
      mounted.current = false;
    };
  }, [dispatch, userAddress, balance, contract, currentChain]);

  return (
    <React.Fragment>
      {policyIds.map((policyId, idx) => (
        <PolicyCard policyId={policyId?.value} automation={automation} key={idx} />
      ))}
    </React.Fragment>
  );
};

ActivePoliciesList.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const { balance, contract } = ownProps;
  const userAddress = selectUserAddress(state.UserReducer);
  const policyIds = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(userAddress, balance, contract));
  const currentChain = selectCurrentChain(state.EthereumReducer);
  return { userAddress, policyIds, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(ActivePoliciesList));
