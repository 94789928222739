module.exports = {
  environment: {
    version: process.env.REACT_APP_ENV_VERSION || "development",
  },
  defaultChain: {
    name: process.env.REACT_APP_DEFAULT_CHAIN,
    id: parseInt(process.env.REACT_APP_DEFAULT_CHAIN_ID),
  },
  ethereum: {
    retry: {
      timeout: parseInt(process.env.REACT_APP_ETH_RETRY_TIMEOUT || 500),
      count: parseInt(process.env.REACT_APP_ETH_RETRY_COUNT || 10),
      transactCount: parseInt(process.env.REACT_APP_ETH_TRANSACT_COUNT || 300),
    },
    defaultMaxAge: parseInt(process.env.REACT_APP_ETH_DEFAULT_MAX_AGE || 3000),
  },
  refresh: {
    default: parseInt(process.env.REACT_APP_DEFAULT_REFRESH || 120000),
    userInformation: parseInt(process.env.REACT_APP_REFRESH || 15000),
  },
  track: {
    name: "Track Wallet",
  },
  walletConnect: {
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "3d0c867c26b912b5b0917c1223746b7e",
  },
  healthFactor: {
    middleHf: process.env.REACT_APP_MIDDLE_HF || 1.05,
    finalHf: process.env.REACT_APP_FINAL_HF || 1.1, // + 10% of middleHf
  },
  gas: {
    increase: 130, // 30%
  },
  policy: {
    minimumPrice: process.env.REACT_APP_MINIMUM_PRICE || 1,
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || "notoken",
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    enabled: (process.env.REACT_APP_ROLLBAR_ENABLED || "false") === "true",
  },
  blockedCountries: process.env.REACT_APP_BLOCKED_COUNTRIES.split(","),
};
