import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Form, InputGroup } from "react-bootstrap";
/* Helpers and Selectors */
import { selectPayout, selectTotalDebtBase } from "../../../store/securePositionModal/selectors";
import { strToDec, strToDecZ } from "../../../helpers/number_format_helper";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

const PayoutForm = ({ t, payoutAmount, debt }) => {
  let dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch({ type: "SET_PAYOUT_AMOUNT", payoutAmount: e.target.value });
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>{t("Payout")}</Form.Label>
      <InputGroup>
        <Form.Control
          id="payout"
          placeholder="$ 0.00"
          isInvalid={
            payoutAmount &&
            payoutAmount !== "" &&
            (!strToDecZ(payoutAmount) || (strToDecZ(payoutAmount) && debt?.value?.lt(strToDec(payoutAmount))))
          }
          value={payoutAmount}
          onChange={(e) => handleChange(e)}
        />
        <InputGroup.Text>{t("USD")}</InputGroup.Text>
        <Form.Control.Feedback type="invalid">
          {!strToDecZ(payoutAmount) && t("Please provide a valid amount.")}
          {payoutAmount &&
            strToDec(payoutAmount) &&
            debt?.value?.lt(strToDec(payoutAmount)) &&
            `${t("Payout cannot be greater than total debt")} (${debt?.value?.toFixed(2)})`}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

PayoutForm.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const payoutAmount = selectPayout(state.SecurePositionModalReducer);
  const debt = selectTotalDebtBase(state.SecurePositionModalReducer);
  return { payoutAmount, debt };
};

export default connect(mapStateToProps)(withTranslation()(PayoutForm));
