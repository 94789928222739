import React from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectAutomation } from "../../../store/securePositionModal/selectors";
/* i18n */
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../style.scss";

const AutomationDescription = ({ t, automation }) => {
  return (
    <Row>
      {automation === "receiveMoney" && (
        <h5>
          {t(
            "Receive Money: You will receive the payout in your wallet. Warning: this will not change your AAVE health factor nor avoid the potential liquidation."
          )}
        </h5>
      )}
      {automation === "repayLoan" && (
        <h5>
          {t(
            "Repay Loan: The payout will repay your AAVE loan in USDC. If no outstanding loan, it will deposit the funds into AAVE."
          )}
        </h5>
      )}
      {automation === "buyTheDip" && (
        <h5>
          {t(
            "Buy the Dip: The payout will be used to buy ETH that will be deposited as collateral, improving your health factor."
          )}
        </h5>
      )}
    </Row>
  );
};

AutomationDescription.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const automation = selectAutomation(state.SecurePositionModalReducer);
  return { automation };
};

export default connect(mapStateToProps)(withTranslation()(AutomationDescription));
