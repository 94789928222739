import { all, fork } from "redux-saga/effects";
//public
import LayoutSaga from "./layout/saga";
import userSaga from "./user/saga";
import {
  getEncodedCall,
  getContract,
  getABI,
  getAbiName,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
} from "../contractRegistry";
import { selectChainId, selectProvider, selectUserAddress } from "./user/selectors";
import { ethereumSaga, initializeEthereumStore } from "ethereum-store/src/package-index";

initializeEthereumStore({
  getEncodedCall,
  getContract,
  getABI,
  getAbiName,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
  selectUserAddress,
  selectChainId,
  selectProvider,
});

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(userSaga),
    fork(ethereumSaga),
  ]);
}
