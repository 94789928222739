import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

const userRoutes = [
  { path: "/", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "*", exact: true, component: () => <Navigate to="/" /> },
];

export { userRoutes };
