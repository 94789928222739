import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { Route, Routes } from "react-router-dom";
import HorizontalLayout from "./components/HorizontalLayout/";
/* Redux */
import { connect, useDispatch } from "react-redux";
import { userRoutes } from "./routes/allRoutes";
/* Google analytics */
import ReactGA from "react-ga4";
/* Style */
import "./assets/scss/theme.scss";

initializeReactGA();

const App = () => {
  let dispatch = useDispatch();
  const Layout = HorizontalLayout;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "ETH_DISPATCH_CLOCK" });
    }, 500);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <React.Fragment>
      <Routes>
        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <Layout>
                <route.component />
              </Layout>
            }
            exact
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

function initializeReactGA() {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.send("pageview");
  }
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return { layout: state.Layout };
};

export default connect(mapStateToProps, null)(App);
