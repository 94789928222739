export const providers = {};

export function registerProvider(provider) {
  providers[1] = provider;
  return 1;
}

export function getProvider(providerId) {
  return providers[providerId];
}
