import wbtcLogo from "./assets/images/wbtc-logo.png";
import wethLogo from "./assets/images/weth-logo.png";
import ethLogo from "./assets/images/eth-logo.png";
import arbLogo from "./assets/images/arbitrum-logo.png";
import wmaticLogo from "./assets/images/matic-logo.png";

export const assets = {
  mumbai: [
    {
      name: "WBTC",
      priceRiskModule: "0x66808A655348D4D535E4341180426ed3ed3F42B9",
      rmName: "BTC Price",
      priceOracle: "0xb6e0c7418514376b6fcb81640b0e085213832fc4",
      image: wbtcLogo,
      duration: [7, 15, 30],
    },
    {
      name: "WETH",
      priceRiskModule: "0xEE9B2de9ae3a834E16AaBbCB45dce19Fd8878e86",
      rmName: "ETH Price",
      priceOracle: "0x6dee0f71b74b17c3b6bd95a869b7cb5b12d4dfd5",
      image: wethLogo,
      duration: [7, 15, 30],
    },
    {
      name: "WMATIC",
      priceRiskModule: "0x59bbbB78746B88510c9c39ED930dFEdFB30ca099",
      rmName: "WMATIC Price",
      priceOracle: "0x0cecadddec2cd69d7102086d2398e36529f05a27",
      image: wmaticLogo,
      duration: [7, 15, 30],
    },
  ],
  arbgoerli: [
    {
      name: "WBTC",
      priceRiskModule: "0x3CAda3fEcE9952955E21B46C81E23B254893299B",
      rmName: "BTC Price",
      priceOracle: "0xE169BBB6a402078Ec968a6Fd6D31667EE29A7aE7",
      image: wbtcLogo,
      duration: [7, 15, 30],
    },
    {
      name: "ETH",
      priceRiskModule: "0x7F885a55cb8047084F11b4A2791229b50d50c251",
      rmName: "ETH Price",
      priceOracle: "0x9D3DBcE510ecFCC70F77230B2F2Ba8184F58583A",
      image: ethLogo,
      duration: [7, 15, 30],
    },
    {
      name: "ARB",
      priceRiskModule: "0xD315b3a8fD4C59f5641Cdd0603a5Bbb9a9026eFb",
      rmName: "ARB Price",
      priceOracle: "0x3d7a12F106F8905ac830960DD81F58f9B4f09fa7",
      image: arbLogo,
      duration: [7, 15, 30],
    },
  ],
  arbitrum: [
    {
      name: "WBTC",
      priceRiskModule: "0x37fE456EFF897CB5dDF040A5e95f399EaBc162ca",
      rmName: "BTC Price",
      priceOracle: "0xcd6e488259BCf07c7a6904A361881Cf5B99DED44",
      image: wbtcLogo,
      duration: [7, 15, 30],
    },
    {
      name: "ETH",
      priceRiskModule: "0xD66eAe1a6e9102Da728FbB2556fc6a8189d11F95",
      rmName: "ETH Price",
      priceOracle: "0xB4FE645a49210DbC63e7C260D0Df4860ba059b52",
      image: ethLogo,
      duration: [7, 15, 30],
    },
    {
      name: "ARB",
      priceRiskModule: "0x4e21e0B3890D7FF1435F53e67793148f5Ec379A4",
      rmName: "ARB Price",
      priceOracle: "0xD5c186D04dF2a3c01E01cb545A8E4b1C4ee8bFe4",
      image: arbLogo,
      duration: [7, 15, 30],
    },
  ],
};

export const automations = {
  mumbai: [
    // {key: "repayLoan", address: "", name: "Receive Money"},
    // {key: "buyTheDip", address: "", name: "Receive Money"},
    { key: "receiveMoney", address: "0x78f1626224f48A4E24FD7Cc7bF070A1740D5cafD", name: "Receive Money" },
  ],
  arbgoerli: [
    { key: "repayLoan", address: "0xae374f8f8bD1d2a02c625eCa127320ea9e774cdb", name: "Repay Loan" },
    { key: "buyTheDip", address: "0xF111DF421931caAF0c7546897205B072400351f3", name: "Buy The Dip" },
    { key: "receiveMoney", address: "0x329731D4FB96Ec52039e222bC4cC67a86b582A86", name: "Receive Money" },
  ],
  arbitrum: [
    { key: "repayLoan", address: "0x04bc2afBF4f30E2288070aC4ae301a887A44b2F8", name: "Repay Loan" },
    { key: "buyTheDip", address: "0xd8F30147961b99d89222E660b3d3855C5eB12330", name: "Buy The Dip" },
    { key: "receiveMoney", address: "0x62287aa6952Bc58533031Fe9d4df86d09B999Ac8", name: "Receive Money" },
  ],
};
