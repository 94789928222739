import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
/* Helpers and Selectors */
import { selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { getChainDataById } from "../../helpers/eth_store_helper";
import { selectProvider } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { chains } from "../../availableChains";
import colors from "../../colors";
import "./style.scss";

const SwitchNetworkButton = ({ user, currentChain }) => {
  let dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };

  const switchNetwork = async (chain) => {
    let provider = selectProvider(user);
    let hexId = "0x" + chain.id.toString(16);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexId }],
      });
      dispatch({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
    } catch (switchError) {
      // This error code indicates that TrackWallet provider does not support the requested method: wallet_switchEthereumChain
      if (switchError.code === 4200) {
        try {
          dispatch({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
        } catch (addError) {
          throw addError;
        }
      }
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hexId,
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                blockExplorerUrls: [chain.scan],
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <div className="d-lg-inline-block">
      {!user.address ? (
        <></>
      ) : (
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block wallet-btn language-switch">
          <DropdownToggle
            className="font-size-20 btn header-item waves-effect"
            tag="button"
            style={{ color: colors.secondary }}
          >
            <span>
              <img src={currentChain.logo} alt="" height="20" />
            </span>{" "}
            {currentChain.name}
            <i className="uil uil-angle-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end dropdown-border">
            {chains.map((chain) => (
              <DropdownItem key={chain.id} className="font-size-20" onClick={() => switchNetwork(chain)}>
                <span>
                  <img src={chain.logo} alt="" height="20" />
                </span>{" "}
                {chain.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

SwitchNetworkButton.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const c = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(c.id);
  return { currentChain };
};

export default connect(mapStateToProps)(withTranslation()(SwitchNetworkButton));
