import { chains } from "../availableChains";

export function validTransaction(lastTransaction) {
  return !lastTransaction || !(lastTransaction && !lastTransaction.state && lastTransaction.state !== "QUEUED");
}

export function customErrorParser(error) {
  const stringToFind = 'reason":"';
  let custom = JSON.stringify(error);
  if (custom && custom.includes(stringToFind)) {
    let start_index = custom.indexOf(stringToFind) + stringToFind.length;
    let end_index = custom.indexOf('",', start_index);
    let new_string = custom.slice(start_index, end_index);
    return new_string;
  }
  return undefined;
}

export const types = {
  Permit: [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ],
};

export function getChainDataById(id) {
  return chains.find(function (chain) {
    return chain["id"] === id;
  });
}
