// Secure Position Modal Redux States
import { getChainDataById } from "../../helpers/eth_store_helper.js";
import { assets, automations } from "../../chainConfigs.js";
import { defaultChain } from "../../config.js";
import {
  getAmountByAsset,
  getPayoutByAutomation,
  getSuggestedTriggerPrice,
  getTimestampFromDays,
} from "../../helpers/secure_position_helper";
import {
  SET_BLOCKCHAIN_STATE,
  SET_ASSET,
  SET_TRIGGER_PRICE,
  SET_PAYOUT_AMOUNT,
  SET_DURATION,
  SET_AUTOMATION,
} from "./actionTypes";

const INIT_STATE = {
  aaveUserData: null,
  assetPrices: {},
  asset: {},
  triggerPrice: "",
  payoutAmount: "",
  duration: {},
  automation: null,
  error: {
    message: "",
  },
};

const SecurePositionModalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BLOCKCHAIN_STATE:
      const currentChain = getChainDataById(action.chainId) || { environment: { network: defaultChain } };
      const defaultAsset = assets[currentChain.environment.network][1];
      const defaultDuration = defaultAsset.duration[0];
      const defaultAutomation = automations[currentChain.environment.network][0].key;
      state = {
        ...state,
        aaveUserData: action.aaveUserData,
        assetPrices: action.prices,
        asset: defaultAsset,
        triggerPrice: getSuggestedTriggerPrice(action.aaveUserData.healthFactor, action.prices[defaultAsset.name]),
        payoutAmount: getPayoutByAutomation(defaultAutomation, action.aaveUserData.totalDebtBase.value),
        automation: defaultAutomation,
        duration: { days: defaultDuration, timestamp: getTimestampFromDays(defaultDuration) },
      };
      break;

    case SET_ASSET:
      let hf = state.aaveUserData?.healthFactor;
      let price = state.assetPrices[action.asset.name];
      let tp = getSuggestedTriggerPrice(hf, price);
      let amount = getAmountByAsset(state.automation, price, state.aaveUserData, tp);
      state = {
        ...state,
        asset: action.asset,
        triggerPrice: tp,
        payoutAmount: amount !== "" ? getPayoutByAutomation(state.automation, amount) : "",
        duration: { days: action.asset.duration[0], timestamp: getTimestampFromDays(action.asset.duration[0]) },
      };
      break;

    case SET_TRIGGER_PRICE:
      let a = getAmountByAsset(
        state.automation,
        state.assetPrices[state.asset.name],
        state.aaveUserData,
        action.triggerPrice
      );
      state = {
        ...state,
        triggerPrice: action.triggerPrice,
        payoutAmount: getPayoutByAutomation(state.automation, a),
      };
      break;

    case SET_PAYOUT_AMOUNT:
      state = { ...state, payoutAmount: action.payoutAmount };
      break;

    case SET_DURATION:
      state = { ...state, duration: action.duration };
      break;

    case SET_AUTOMATION:
      let assetAmount = getAmountByAsset(
        action.automation,
        state.assetPrices[state.asset.name],
        state.aaveUserData,
        state.triggerPrice
      );
      const payout = getPayoutByAutomation(action.automation, assetAmount);
      state = { ...state, automation: action.automation, payoutAmount: payout };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SecurePositionModalReducer;
