import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { selectAutomation } from "../../../store/securePositionModal/selectors";
import { getChainDataById } from "../../../helpers/eth_store_helper";
import { automations } from "../../../chainConfigs";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "../style.scss";

const Automation = ({ t, automation, currentChain }) => {
  let dispatch = useDispatch();

  const handleAutomation = (automation) => {
    dispatch({ type: "SET_AUTOMATION", automation: automation });
  };

  return (
    <Row>
      {automations[currentChain.environment.network].map((aut, idx) => (
        <Col md={4} xs={4} key={idx}>
          <Button
            id="automation-btn"
            variant={aut.key === automation ? "secondary" : "sky-blue"}
            onClick={() => handleAutomation(aut.key)}
          >
            {aut.name}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

Automation.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const automation = selectAutomation(state.SecurePositionModalReducer);
  const chain = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(chain.id);
  return { automation, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(Automation));
