import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Row, Col } from "react-bootstrap";
/* Helpers and Selectors */
import { selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { getChainDataById } from "../../helpers/eth_store_helper";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./style.scss";

const TxModal = ({ t, show, onHide, tx, currentChain }) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Transaction detail")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <p>{t("Type")}:</p>
          </Col>
          <Col md={6}>
            <p>{tx.method.toUpperCase()}</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>{t("Tx Hash")}:</p>
          </Col>
          <Col md={6}>{tx.txHash.substring(0, 6) + "..." + tx.txHash.slice(-6)}</Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>{t("Status")}:</p>
          </Col>
          {tx.state === "MINED" ? (
            <Col md={6}>
              <p style={{ color: "green" }}>
                <i className="uil uil-check" /> {tx.state}
              </p>
            </Col>
          ) : tx.state === "QUEUED" ? (
            <Col md={6}>
              <p style={{ color: "blue" }}>
                <i className="uil uil-clock" /> {tx.state}
              </p>
            </Col>
          ) : (
            <Col md={6}>
              <p style={{ color: "blue" }}>{tx.state}</p>
            </Col>
          )}
        </Row>
        <Row>
          {tx.state === "EXPIRED" ? <p>{t("Please check the state of your transaction in block explorer")}</p> : <></>}
        </Row>
        <Row>
          <Col>
            <a target="_blank" rel="noopener noreferrer" href={currentChain.scan + "tx/" + tx.txHash}>
              <i className="uil uil-external-link-alt" />
              {t(" View on block explorer")}
            </a>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

TxModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const chain = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(chain.id);
  return { currentChain };
};

export default connect(mapStateToProps)(withTranslation()(TxModal));
