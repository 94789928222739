import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Col, Form, InputGroup, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectAssetPrice, selectTriggerPrice } from "../../../store/securePositionModal/selectors";
import { strToDecZ } from "../../../helpers/number_format_helper";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

const TriggerPriceForm = ({ t, triggerPrice, price }) => {
  let dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch({ type: "SET_TRIGGER_PRICE", triggerPrice: e.target.value });
  };

  return (
    <Row className="mb-3">
      <Col md={4} xs={4}>
        <h6>{t("Current price")}</h6>
      </Col>
      <Col md={1} xs={1}></Col>
      <Col md={7} xs={7}>
        <h6>{t("Trigger price")}</h6>
      </Col>
      <Col md={4} xs={4}>
        <Form.Control disabled value={price?.value ? `$${price.value.toFixed(2)}` : ""} />
      </Col>
      <Col md={1} xs={1} id="centered">
        <i className="uil uil-arrow-right font-size-20" />
      </Col>
      <Col md={7} xs={7}>
        <InputGroup>
          <Form.Control
            placeholder="$ 0.00"
            id="liquidity"
            isInvalid={triggerPrice !== "" && !strToDecZ(triggerPrice)}
            value={triggerPrice}
            onChange={(e) => handleChange(e)}
          />
          <InputGroup.Text>{t("USD")}</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {!strToDecZ(triggerPrice) && t("Please provide a valid amount.")}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  );
};

TriggerPriceForm.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const triggerPrice = selectTriggerPrice(state.SecurePositionModalReducer);
  const price = selectAssetPrice(state.SecurePositionModalReducer);
  return { triggerPrice, price };
};

export default connect(mapStateToProps)(withTranslation()(TriggerPriceForm));
