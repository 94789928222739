import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./style.scss";

const TxButton = ({ t, chainId, currentChain, onClick, disabled, text }) => {
  return (
    <Button className="btn btn-primary btn-lg" onClick={onClick} disabled={chainId !== currentChain.id || disabled}>
      {chainId !== currentChain.id ? t("Wrong Network") : text}
    </Button>
  );
};

TxButton.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(TxButton);
