import { call, put, takeEvery, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { getProvider } from "../../providers";

// User Redux States
import { SET_PROVIDER, SET_USER, SET_CHAIN_ID } from "./actionTypes";
import { getChainDataById } from "../../helpers/eth_store_helper";
import { defaultChain } from "../../config";

function providerListenerChannel(provider) {
  return eventChannel((emitter) => {
    provider.on("accountsChanged", (evtData) => {
      emitter({
        type: SET_USER,
        payload: evtData[0],
        canTransact: true, // Track Wallet can't emit this event, so it's always true
      });
    });

    provider.on("chainChanged", (evtData) => {
      let chain = getChainDataById(parseInt(evtData, 16));
      chain = chain ? chain : getChainDataById(defaultChain.id);
      emitter({
        type: SET_CHAIN_ID,
        payload: parseInt(evtData, 16),
      });
      emitter({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
    });

    provider.on("disconnect", () => {
      emitter({
        type: SET_PROVIDER,
        payload: null,
      });
      emitter(END);
    });
    return () => {};
  });
}

export function* listenToEvents({ payload }) {
  if (payload == null) return;
  const channel = yield call(providerListenerChannel, getProvider(payload));
  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } finally {
  }
}

export function* userSaga() {
  yield takeEvery(SET_PROVIDER, listenToEvents);
}

export default userSaga;
