import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import { Card, Col, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import SecurePositionModal from "../Modal/SecurePosition/SecurePositionModal";
import BlurredNumber from "../Common/Blurred/BlurredNumber";
import TxModal from "../Modal/TxModal";
import Asset from "../Common/Asset";
/* Helpers and Selectors */
import { BNToDecimal, compareWithMaxUint } from "../../helpers/number_format_helper";
import { getChainDataById } from "../../helpers/eth_store_helper";
import {
  selectEthCallMultiple,
  selectLastTransact,
  selectCurrentChain,
} from "ethereum-store/src/store/ethereum/selectors";
import aaveLogo from "../../assets/images/aave-logo.png";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./style.scss";

const componentEthCalls = (userAddress, chain) => {
  return [{ address: chain.aave.pool, abi: "AavePool", method: "getUserAccountData", args: [userAddress] }];
};

const Positions = ({ t, user, totalCollateralBase, totalDebtBase, healthFactor, lastTx, currentChain }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);
  const [secureModal, showSecureModal] = useState(false);
  const [txModal, showTxModal] = useState(false);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "positions",
      componentEthCalls: componentEthCalls(user.address, currentChain),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "positions" });
      mounted.current = false;
    };
  }, [dispatch, user.address, currentChain]);

  useEffect(() => {
    if (lastTx && lastTx.state === "QUEUED" && secureModal) {
      if (lastTx.method !== "approve") showSecureModal(false);
      showTxModal(true);
    }
  }, [lastTx, txModal, secureModal]);

  return (
    <>
      <h3>{t("Your positions")}</h3>
      <br />
      <Row>
        <Col md={4}>
          <Card id="position-card">
            <div id="collaretal-base-number">
              $
              <BlurredNumber number={totalCollateralBase} options={{ maximumFractionDigits: 2, notation: "compact" }} />
            </div>
            <Asset image={aaveLogo} name={t("Aave")} />
            <br />
            <div>
              <Button disabled={healthFactor?.value === "∞"} onClick={() => showSecureModal(true)}>
                {t("Secure Position")}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      {secureModal && (
        <SecurePositionModal
          show={secureModal}
          onHide={() => showSecureModal(false)}
          collateral={totalCollateralBase}
          debt={totalDebtBase}
          healthFactor={healthFactor}
        />
      )}
      {lastTx && (lastTx.state === "QUEUED" || lastTx.state === "MINED" || lastTx.state === "EXPIRED") && (
        <TxModal show={txModal} onHide={() => showTxModal(false)} tx={lastTx || {}} />
      )}
    </>
  );
};

Positions.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const userAddress = ownProps.user.address;
  const c = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(c.id);
  const [aavePositions] = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(userAddress, currentChain));
  const lastTx = selectLastTransact(state.EthereumReducer);
  const totalCollateralBase = aavePositions.value
    ? { value: BNToDecimal(aavePositions.value.totalCollateralBase, 8), state: "LOADED" }
    : {};

  const totalDebtBase = aavePositions.value
    ? { value: BNToDecimal(aavePositions.value.totalDebtBase, 8), state: "LOADED" }
    : {};
  const healthFactor = aavePositions.value
    ? { value: compareWithMaxUint(aavePositions.value.healthFactor, 18), state: "LOADED" }
    : {};

  return { aavePositions, totalCollateralBase, totalDebtBase, healthFactor, lastTx, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(Positions));
