import PropTypes from "prop-types";
/* Components */
import Asset from "../../Common/Asset";
import { Button, Col, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectCurrentChain } from "ethereum-store/src/store/ethereum/selectors";
import { selectAsset } from "../../../store/securePositionModal/selectors";
import { getChainDataById } from "../../../helpers/eth_store_helper";
import { assets } from "../../../chainConfigs";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "../style.scss";

const SupportedAssets = ({ t, asset, currentChain }) => {
  let dispatch = useDispatch();

  const handleAssetSelected = (asset) => {
    dispatch({ type: "SET_ASSET", asset: asset });
  };

  return (
    <Row>
      {assets[currentChain.environment.network].map((a, idx) => (
        <Col md={4} xs={4} key={idx}>
          <Button
            id="asset-btn"
            variant={asset.name === a.name ? "secondary" : "sky-blue"}
            onClick={() => handleAssetSelected(a)}
          >
            <Asset image={a.image} name={a.name} height={28} font={14} />
          </Button>
        </Col>
      ))}
    </Row>
  );
};

SupportedAssets.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const asset = selectAsset(state.SecurePositionModalReducer);
  const chain = selectCurrentChain(state.EthereumReducer);
  const currentChain = getChainDataById(chain.id);
  return { asset, currentChain };
};

export default connect(mapStateToProps)(withTranslation()(SupportedAssets));
