import { getChainDataById } from "./eth_store_helper";
import { registerProvider } from "../providers";
import { defaultChain } from "../config";

export function refreshWallet(dispatch, provider, user, chainId, canTransact) {
  let chain = getChainDataById(chainId);
  chain = chain ? chain : getChainDataById(defaultChain.id);
  dispatch({ type: "SET_PROVIDER", payload: registerProvider(provider) });
  dispatch({ type: "SET_USER", payload: user, canTransact: canTransact });
  dispatch({ type: "SET_CHAIN_ID", payload: chainId });
  dispatch({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
}

export function shortAddress(address) {
  return address?.substring(0, 6) + "..." + address.slice(-4);
}
