import Big from "big.js";
const { ethers } = require("ethers");

const defaultNumberFormatOptions = {
  maximumFractionDigits: 1,
  notation: "compact",
  compactDisplay: "short",
};

export const compareWithMaxUint = (number, decimals) => {
  if (number === ethers.MaxUint256) {
    return "∞";
  } else {
    return BNToDecimal(number, decimals);
  }
};

export const compactNumber = (number, options) => {
  options = { ...defaultNumberFormatOptions, ...options };

  number = new Intl.NumberFormat("en-US", options).format(number);
  return number;
};

export const formatPercentage = (number, decimals = 2) => {
  return (number * 100).toFixed(decimals);
};

export const convertToDays = (seconds) => {
  let secondsInDay = 24 * 60 * 60;
  return seconds / secondsInDay;
};

export const BNToDecimal = (number, decimals) => {
  return Big(number).div(10 ** decimals);
};

export const BigToString = (bn) => {
  return bn.toString();
};

export const hoursToDays = (hours) => {
  return hours / 24;
};

export const arrayOfBNToDecimal = (value, decimals) => {
  let bigArray = value.map((item) => {
    return Big(item).div(10 ** decimals);
  });
  return bigArray;
};

export const toDecimal = (number) => {
  return Big(number);
};

export const decimalToBN = (number, decimals = 6) => {
  return ethers.parseUnits(number.toString(), decimals);
};

export const strToDec = (numberAsString) => {
  try {
    return new Big(numberAsString);
  } catch (error) {
    return undefined;
  }
};

export const strToDecZ = (numberAsString) => {
  // If the value is zero returns "0" as integer, this is to avoid the fact (error IMO) that
  // !Big(0) === false
  try {
    let ret = new Big(numberAsString);
    if (ret.eq(0)) return 0;
    else return ret;
  } catch (error) {
    return undefined;
  }
};

export const strToBN = (numberAsString, decimals = 6) => {
  let dec = strToDec(numberAsString);
  if (dec === undefined) return undefined;
  return decimalToBN(dec, decimals);
};

export const strToBlockchainData = (numberAsString, decimals = 6) => {
  return strToDec(numberAsString) ? ethers.parseUnits(numberAsString, decimals) : undefined;
};

export const getDateFromTimestamp = (timestamp) => {
  const date = new Date(ethers.toNumber(timestamp) * 1000);
  return date.toLocaleDateString();
};
